// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import ExploreData from './ExploreData';
import InteractiveMap from './InteractiveMap';
import TimeLine from './TimeLine';
import Splash from './Splash';
import LoadingScreen from './LoadingScreen';
import SearchPage from './SearchPage';
import CommunityPage from './CommunityPage';
import EarlyAccessPage from './EarlyAccessPage';
import ChatWithGptPage from './ChatWithGptPage';
import DonatePage from './DonatePage';  // Import DonatePage

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/loading" element={<LoadingScreen />} />
        <Route path="/home" element={<Home />} />
        <Route path="/explore-data" element={<ExploreData />} />
        <Route path="/interactive-map" element={<InteractiveMap />} />
        <Route path="/timeline" element={<TimeLine />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/community" element={<CommunityPage />} />
        <Route path="/early-access" element={<EarlyAccessPage />} />
        <Route path="/chat-with-gpt" element={<ChatWithGptPage />} />
        <Route path="/donate" element={<DonatePage />} />  {/* Add DonatePage route */}
      </Routes>
    </Router>
  );
};

export default App;
