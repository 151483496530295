// DonatePage.js

import React from 'react';
import './DonatePage.css';

const DonatePage = () => {
  return (
    <div className="donate-container">
      <h1>Support Us</h1>
      <p>Your donation helps us continue our work.</p>
      <a href="https://cash.app/$stjame5" className="donate-link" target="_blank" rel="noopener noreferrer">
        Donate SINE via Cash App
      </a>     
      <a href="https://cash.app/$kaybug5313" className="donate-link" target="_blank" rel="noopener noreferrer">
        Donate SiVe via Cash App
      </a>
    </div>
  );
};

export default DonatePage;
