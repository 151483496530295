import React from 'react';
import './Splash.css';
import { useNavigate } from 'react-router-dom';
import MovingBall from './MovingBall.jsx'; // Adjust the path if needed

const Splash = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    const splashContainer = document.querySelector('.splash-container');

    // Trigger the fade-out effect
    splashContainer.classList.add('fade-out');

    // After the fade-out completes, navigate to the loading screen
    setTimeout(() => {
      navigate('/loading'); // Navigate to the loading screen
    }, 1500); // Duration of the fade-out animation
  };

  return (
    <div className="splash-container" onClick={handleClick}>
      <MovingBall /> {/* Add MovingBall component here */}
      <div className="splash-text">Site Loading...</div>
      <div className="logo-container">
        <img src={`${process.env.PUBLIC_URL}/sine_wave_logo.png`} alt="Sine Wave Logo" className="sine-wave-logo" />
      </div>
      <div className="splash-continue">Continue</div>
    </div>
  );
};

export default Splash;
