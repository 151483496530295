import React, { useEffect, useRef } from 'react';
import './MovingBall.css';

const MovingBall = () => {
  const ballRef = useRef(null);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const animationFrameId = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    contextRef.current = context;
    
    let direction = Math.floor(Math.random() * 4); // Random initial direction
    let x = 0;
    let y = 0;
    let stepIndex = 0;

    // Array of possible step sizes
    const stepSizes = [5, 3, 9];

    const moveBall = () => {
      if (!ballRef.current) return;

      // Get a random number of steps from the array
      const steps = stepSizes[Math.floor(Math.random() * stepSizes.length)];

      const move = () => {
        if (stepIndex < steps) {
          // Draw trail
          drawTrail(x, y);

          if (direction === 0) {
            x += 10; // Move right
          } else if (direction === 1) {
            y += 10; // Move down
          } else if (direction === 2) {
            x -= 10; // Move left
          } else if (direction === 3) {
            y -= 10; // Move up
          }

          // Apply boundary checks to wrap around screen if necessary
          if (x > window.innerWidth) x = 0;
          if (x < 0) x = window.innerWidth;
          if (y > window.innerHeight) y = 0;
          if (y < 0) y = window.innerHeight;

          ballRef.current.style.transform = `translate(${x}px, ${y}px)`;
          stepIndex++;
          animationFrameId.current = requestAnimationFrame(move);
        } else {
          // Change direction every movement sequence
          direction = (direction + 1) % 4;
          stepIndex = 0;
          setTimeout(() => {
            animationFrameId.current = requestAnimationFrame(move);
          }, 500); // Delay before starting the next move
        }
      };

      animationFrameId.current = requestAnimationFrame(move);
    };

    const drawTrail = (x, y) => {
      const context = contextRef.current;

      // Clear previous trail
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the ball's trail
      context.fillStyle = 'rgba(255, 255, 255, 0.3)'; // White with some transparency
      context.beginPath();
      context.arc(x, y, 5, 0, 2 * Math.PI); // Draw small circles as trail
      context.fill();
    };

    moveBall();

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  return (
    <div className="ball-container">
      <canvas ref={canvasRef} className="trail-canvas"></canvas>
      <div ref={ballRef} className="moving-ball"></div>
    </div>
  );
};

export default MovingBall;
